// libraries
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
//components
import { Layout } from '../components/Layout';
// constants
import { BREAKPOINTS } from '../settings';
// actions
import { updateHeaderStyle } from '../store/actions/base';
// helpers
import { renderBlock } from '../helpers/renderBlock';

export default ({ data }) => {
    const components = data.wordpressPage.acf.vacancy_components_page;
    const pageId = data.wordpressPage.wordpress_id;
    let isLightHeader = false;

    const dispatch = useDispatch();

    useEffect(() => {
        updateHeaderStyle(dispatch, isLightHeader);
    }, []);

    return (
        <Layout data={data.wordpressPage}>
            <StyledVacancyPage>
                <StyledDark />
                <StyledLight />
                {components &&
                    components.map((item, i) => {
                        const beginIndex = item.id ? item.id.indexOf('Acf_') : -1;
                        const className = beginIndex === -1 ? 'block_' + i : item.id.slice(beginIndex + 4) + '_' + i;
                        return (
                            <div key={i} className={className}>
                                {renderBlock(item, pageId)}
                            </div>
                        );
                    })}
            </StyledVacancyPage>
        </Layout>
    );
};

const StyledLight = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: repeating-linear-gradient(-60deg, transparent, transparent 520px, rgba(243, 245, 248, 0.05) 0, rgba(243, 245, 248, 0.05) 980px);

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        background-image: repeating-linear-gradient(-60deg, transparent, transparent 700px, rgba(243, 245, 248, 0.05) 0, rgba(243, 245, 248, 0.05) 1350px);
    }
`;
const StyledDark = styled.div`
    position: absolute; 
    width: 100%;
    height: 100%;
    background-image: repeating-linear-gradient(-60deg, transparent, transparent 520px, rgba(243, 245, 248, 0.7) 0, rgba(243, 245, 248, 0.7) 980px);

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        background-image: repeating-linear-gradient(-60deg, transparent, transparent 700px, rgba(243, 245, 248, 0.7) 0, rgba(243, 245, 248, 0.7) 1350px);
    }
`;

const StyledVacancyPage = styled.div`
    position: relative;
`;

export const pageQuery = graphql`
    query ($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            wordpress_id
            type
            title
            yoast {
                title
                metadesc
            }
            polylang_current_lang
            polylang_translations {
                polylang_current_lang
                id
                path
                slug
                status
            }
            acf {
                vacancy_components_page {
                    ... on WordPressAcf_promo_block {
                        __typename
                        ...PromoBlock
                    }
                    ... on WordPressAcf_short_info_block {
                        __typename
                        ...ShortInfoBlock
                    }
                    ... on WordPressAcf_inform_tabs_block {
                        __typename
                        ...InformTabsBlock
                    }
                    ... on WordPressAcf_office_block {
                        __typename
                        ...OfficeBlock
                    }
                    ... on WordPressAcf_employee_say_block {
                        __typename
                        ...EmployeeSayBlock
                    }
                    ... on WordPressAcf_employee_history_block {
                        __typename
                        ...EmployeeHistoryBlock
                    }
                    ... on WordPressAcf_open_vacancies_block {
                        __typename
                        ...OpenVacanciesBlock
                    }
                }
            }
        }
    }
`;
